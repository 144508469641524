<template>
  <div id="appPrint" class="geral">
    <div class="box-button">
      <button
        @click="generatePDF()"
        :disabled="codigo_agrupador == '' || !liberaPdf"
        class="button-sup"
        :class="{ disab: !liberaPdf }"
      >
        Gerar PDF
      </button>
      <button @click="novaConsulta()" class="button-sup consulta">
        Nova consulta
      </button>
    </div>
    <div class="spinnerBG" v-if="liberaSpinner">
      <Loading class="spinner" />
    </div>
    <div class="boxResult" v-if="codigo_agrupador != ''">
      <ResultadosSuperBox
        ref="resultadosSuperBox"
        :produtos="produtos"
        :campos="campos"
        :contexto="contexto"
        :produtosSelecionados="produtosSelecionados"
        :codigo_agrupador="codigo_agrupador"
        :gerandoPdf="gerandoPdf"
        :liberaTelaSuperbox="liberaTelaSuperbox"
        :liberaConsultaSuperbox="liberaConsultaSuperbox"
        @liberarPdf="liberarPdf"
        @travarPdf="travarPdf"
      />
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { produtosStore } from "@/stores/produtos";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import ResultadosSuperBox from "@/components/SuperBox/ResultadosSuperBox.vue";
import { createPDFZip, createPDF } from "@/services/planilha.service.js";

import Loading from "@/components/Loading";
import Aos from "aos";
import "aos/dist/aos.css";

export default {
  name: "telaSuperBox",
  data() {
    return {
      liberaSpinner: false,
      camposTratados: {},
      liberaPdf: false,
      gerandoPdf: false,
      // camposCheck: false,
    };
  },
  props: {
    produtos: Array,
    campos: Array,
    opcaoInicialSelecionada: String,
    contexto: Array,
    produtosSelecionados: Array,
    liberaTelaSuperbox: Boolean,
    liberaConsultaSuperbox: Boolean,

    // codigo_agrupador: String,
  },

  components: {
    Loading,
    ResultadosSuperBox,
  },

  methods: {
    liberarPdf() {
      this.liberaPdf = true;
    },
    travarPdf() {
      this.liberaPdf = false;
    },

    async generatePDF() {
      const targetElement = this.$refs.resultadosSuperBox.$el;
      this.liberaSpinner = true;
      this.gerandoPdf = true;
      await this.viewMapsAndReturn();

      const codAgr = this.codigo_agrupador;
      let nomeArquivo = `outbox-superboxpf-${codAgr}`;

      if (this.tipoDownloadConsulta == "zip") {
        createPDFZip(targetElement, nomeArquivo);
      } else {
        createPDF(targetElement, nomeArquivo);
      }
      setTimeout(() => {
        this.liberaSpinner = false;
        this.gerandoPdf = false;
      }, 3000);
    },

    async viewMapsAndReturn() {
      const maps = document.getElementsByClassName("vue-map-container");
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      for (const map of maps) {
        map.scrollIntoView();
        await delay(2000);
      }
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },

    novaConsulta() {
      if (this.trilhaSelecionada.id > 1) {
        this.$router.push({
          path: "/consultas/superbox/0",
        });
      } else {
        this.$router.push({
          path: "/consultas/superbox/2",
          query: { source: "consulta" },
        });
      }
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
      "syncPreferenciaTipoDownload",
    ]),

    ...mapActions(blocosSuperBoxStore, [
      "alteraBloco",
      "abreTodosBlocos",
      // "fechaTodosBlocos",
    ]),
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      exibirTootipsConsultas: "exibirTootipsConsultas",
      tipoDownloadConsulta: "tipoDownloadConsulta",
    }),

    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      trilhaSelecionada: "trilhaSelecionada",
    }),
  },

  async created() {
    this.liberaSpinner = true;
    Aos.init({ duration: 2000, once: true });
    for (let i = 0; i < this.campos.length; i++) {
      if (this.campos[i].Valor) {
        this.camposTratados[this.campos[i].Nome] = this.campos[i].Valor;
      }
    }
    if (this.opcaoInicialSelecionada == "") {
      this.$router.push("/consultas/superbox/0");
    }

    if (this.contexto.length > 0) {
      this.contexto[this.contexto.length - 1].campos = this.camposTratados;
    }
    this.liberaSpinner = false;
  },
};
</script>

<style lang="scss" scoped>
#appPrint {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 70px;
  overflow: hidden;
  min-height: 60px;

  @media (max-width: 960px) {
    overflow-x: scroll;
    align-items: flex-start;
  }
}

.boxResult {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 960px) {
    justify-content: flex-start;
  }
}

.box-button {
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}

.button-sup {
  width: 130px;
  height: 35px;
  font-size: 12px;
  padding: 1px 5px;
  color: white;
  border: none;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 14px;
  margin: 0px 5px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disab {
    background-image: url(/img/tela-inicial/Botao-Nova-cinza.png);
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}

.spinnerBG {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 125%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 52%;
  transform: scale(1.2);
  z-index: 10;
}

.geral {
  overflow-x: scroll;
}
</style>
