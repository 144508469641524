<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-icon">
            <i class="far fa-file-pdf fa-2x"></i>
          </div>
          <h4 class="title">Documentação de referência:</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="arquivos">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="#">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Arquivo">{{
                item.arquivo
              }}</md-table-cell>
              <md-table-cell md-label="Descrição">{{
                item.descricao
              }}</md-table-cell>
              <md-table-cell md-label="Ações">
                <a :href="item.link" target="_blank">
                  <md-button class="md-simple md-just-icon md-warning">
                    <i class="fas fa-download"></i>
                    <md-tooltip md-direction="bottom">
                      Baixar arquivo
                    </md-tooltip>
                  </md-button>
                </a>
                &nbsp;&nbsp;<small> ({{ item.tamanho }}) </small>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";

export default {
  data() {
    return {
      arquivos: [
        {
          id: 1,
          arquivo: "Política de Privacidade Outbox",
          descricao: "Política de Privacidade no âmbito da OutBox",
          tamanho: "1.30MB",
          link: "/docs/Política de Privacidade Outbox.pdf",
        },
        {
          id: 2,
          arquivo: "Guia de Produtos v2.10",
          descricao: "Data Book completo de nossos produtos digitais",
          tamanho: "2.07MB",
          link: "/docs/Guia de Produtos 2.10 - StepWise.pdf",
        },
        {
          id: 3,
          arquivo: "Guia de Integração v2.0",
          descricao:
            "Guia com detalhes técnicos para a integração com a nossa API",
          tamanho: "2.77MB",
          link: "/docs/Guia de Integração API 2.0 - StepWise.pdf",
        },
        {
          id: 4,
          arquivo: "Institucional StepWise",
          descricao: "Folder institucional de serviços disponíveis da StepWise",
          tamanho: "1.45MB",
          link: "/docs/Institucional - StepWise.pdf",
        },
        {
          id: 5,
          arquivo: "OutBox - Backtest Rendas e limite - Apresentação - v.2",
          descricao:
            "Arquivo de detalhamento da GeoRenda, Renda Presumida e Limite sugerido.",
          tamanho: "1.97MB",
          link: "/docs/OutBox - Backtest Rendas e limite - Apresentação - v.2.pdf",
        },
        {
          id: 6,
          arquivo: "StepWise_EspelhoAtributos_v.25.2 - API",
          descricao:
            "Arquivo de detalhamento (código, nomenclatura e dicionário) dos books, atributos e modelos de prateleira",
          tamanho: "0.92MB",
          link: "/docs/StepWise_EspelhoAtributos_v.25.2 - API.xlsx",
        },
        {
          id: 7,
          arquivo: "Termos de Serviço - Assistente Outbox",
          descricao:
            "Termos de serviço de utilização do Assistente Outbox.",
          tamanho: "0.13MB",
          link: "/docs/Termos de Serviço - Assistente Outbox.pdf",
        },
      ],
    };
  },
  methods: {
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);
  },
};
</script>

<style lang="scss" scoped>
.text-right ::v-deep .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
</style>
