<template>
  <iframe src="https://api2.outbox.com.br" id="documentacao" width="100%"></iframe>
</template>

<script>
export default {};
</script>
<style>
#documentacao {
  height: calc(100vh - 300px);
}
</style>
