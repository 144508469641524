<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card class="box-contato">
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-icon">
            <i class="material-icons">message</i>
          </div>
          <h4 class="title contato">Entre em contato com nossa equipe</h4>
        </md-card-header>

        <ValidationObserver
          v-slot="{ invalid, touched }"
          ref="observerValidacao"
        >
          <md-card-content>
            <div class="md-layout" style="gap: 60px">
              <div class="md-layout-item md-size-33 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field>
                      <label for="motivo">Motivo do contato:</label>
                      <md-select v-model="motivo" name="motivo" id="motivo">
                        <md-option value="Duvida">Dúvida</md-option>
                        <md-option value="Reclamacao"
                          >Reportar problema</md-option
                        >
                        <md-option value="Sugestao">Sugestão</md-option>
                        <md-option value="Solicitação de contrato comercial"
                          >Solicitação de contato comercial</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <ValidationProvider
                      class="md-layout"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <md-field
                        :class="[
                          { 'md-valid': errors.length == 0 && touched },
                          { 'md-error': errors.length > 0 },
                        ]"
                      >
                        <label for="mensagem">Mensagem:</label>
                        <md-textarea
                          v-model="mensagem"
                          id="mensagem"
                          name="mensagem"
                          required
                        ></md-textarea>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="errors.length > 0"
                            >close</md-icon
                          >
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon
                            class="success"
                            v-show="errors.length == 0 && touched"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions class="text-center" style="border-top: none">
            <md-button
              native-type="submit"
              @click.native.prevent="validaEEnvia"
              :disabled="invalid || loading"
              class="md-primary"
              >Enviar</md-button
            >
            <md-progress-spinner
              :md-diameter="15"
              :md-stroke="1"
              md-mode="indeterminate"
              v-show="loading"
            ></md-progress-spinner>
          </md-card-actions>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { userService } from "@/services";
import Swal from "sweetalert2";

export default {
  components: {
    SlideYDownTransition,
    ValidationObserver,
    ValidationProvider,
  },
  props: {},
  data() {
    return {
      loading: false,
      nome: "",
      email: "",
      motivo: "Duvida",
      mensagem: "",
    };
  },
  methods: {
    validaEEnvia() {
      this.$refs.observerValidacao.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;

          userService
            .enviarMensagemSuporte(this.motivo, this.mensagem)
            .then((resultado) => {
              if (resultado) {
                Swal.fire({
                  title: "Sucesso",
                  text: "Mensagem enviada com sucesso!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "md-button md-success",
                  },
                  confirmButtonText: "Ok",
                  showConfirmButton: true,
                }).then((result) => {
                  this.loading = false;
                  this.motivo = "Duvida";
                  this.mensagem = "";
                });
              } else {
                Swal.fire({
                  title: "Erro",
                  text: "Mensagem não enviada, tente novamente mais tarde.",
                  icon: "error",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "md-button md-warning",
                  },
                  confirmButtonText: "Ok",
                  showConfirmButton: true,
                }).then((result) => {
                  this.loading = false;
                });
              }
            });
        }
      });
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  watch: {},
  mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    if (this.$route.query.source === "consulta") {
      let query = { ...this.$route.query };
      delete query.source;
      this.$router.replace({ query });
      this.motivo = "Reclamacao";
    }
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
// .box-contato {
//   border: 1px solid #0070c0;
//   border-radius: 20px;
// }

// .contato {
//   color: #0070c0 !important;
//   text-align: start;
//   font-weight: 500 !important;
//   margin: 10px 0 20px 55px;
// }

// .custom-select {
//   display: inline-block;
//   position: relative;
//   border: none;
//   background-color: transparent;
//   color: #0070c0;
// }

// .custom-select label {
//   position: absolute;
//   top: -15px;
//   left: 17px;
//   background-color: transparent;
//   padding: 0 5px;
//   font-size: 14px;
//   line-height: 18px;
// }

// .custom-select select {
//   padding: 10px;
//   color: #0070c0 !important;
//   border: 1px solid #0070c0;
//   border-radius: 15px;
//   appearance: none;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   width: 100%;
// }

// .custom-input {
//   display: inline-block;
//   position: relative;
//   width: 100%;
//   margin: 0 0 0 13px;
//   color: #0070c0;
// }

// .custom-input label {
//   position: absolute;
//   top: -21px;
//   left: 5px;
//   background-color: transparent;
//   padding: 0 5px;
//   font-size: 14px;
//   line-height: 18px;
// }

// .custom-input textarea {
//   padding: 10px;
//   color: #0070c0 !important;
//   border: 1px solid #0070c0;
//   border-radius: 15px;
//   height: 100px;
//   width: 100%;
//   resize: none;
// }

// .md-valid {
//   label {
//     color: #5cb85c !important;
//   }
// }

// .md-error {
//   label {
//     color: #d9534f !important;
//   }
// }
</style>
