<template>
  <div class="produtos">
    <span class="tipo-pessoa-box">
      <span class="material-icons icone">{{ iconeTipoPessoa }}</span>
      {{ opcaoInicialSelecionada }}
    </span>

    <div class="linha1">
      <div class="disclaimer">Lista de blocos a consultar</div>
    </div>
    <div class="down">
      <span class="searchBox">
        <button
          class="icon-button"
          id="search-button"
          @click="liberaPesquisa = !liberaPesquisa"
        >
          <span class="material-symbols-outlined icon-search">search</span>
        </button>
        <b-tooltip
          :target="'search-button'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          Pesquisar por palavras-chave
        </b-tooltip>
        <md-field
          v-if="liberaPesquisa"
          class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
        >
          <label for="pesquisaProdutos"> Pesquisar </label>
          <md-input id="pesquisaProdutos" v-model="pesquisaProd"></md-input>
        </md-field>
      </span>
      <div class="footer">
        <md-card-actions class="btn-right">
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            class="md-primary"
            @click="passoAnterior()"
            >Anterior
          </md-button>
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            :disabled="produtosSelecionadosLengthSuperBox == 0"
            class="md-primary"
            @click="decideProxPasso()"
            >Próxima
          </md-button>
        </md-card-actions>
      </div>
    </div>
    <div class="cards" :class="{ 'sem-margem': liberaPesquisa }">
      <div
        class="cardBox"
        v-if="
          opcaoInicialSelecionada == 'Pessoa Física' &&
          produtosSuperBoxLista.length > 1
        "
      >
        <div
          class="header super-box"
          :class="{ selecionado: produtosSelecionadosLengthSuperBox > 0 }"
        >
          <div class="header-col" @click="selecionaSuperBox">
            <img
              src="/img/superbox/logo-superbox.png"
              alt="super-box"
              class="img-logo-sb"
            />
          </div>
          <div class="header-col tit" @click="selecionaSuperBox">
            <div class="nome sup">
              <div v-html="highlight(titleSuperBox)"></div>
            </div>
            <div class="descricao">
              <div v-html="highlight(descSuperBox)"></div>
            </div>
          </div>
          <div
            class="header-col linha"
            :class="{ selecionado: produtosSelecionadosLengthSuperBox > 0 }"
          ></div>
          <div class="header-col selecao">
            <img
              src="/img/selecao-produtos/CaixadeSelecao-selecionada.png"
              v-if="produtosSelecionadosLengthSuperBox > 0"
              class="select-icon"
              @click="selecionaSuperBox"
            />

            <img
              src="/img/selecao-produtos/CaixadeSelecao.png"
              v-else
              class="select-icon nao-selecionado"
              @click="selecionaSuperBox"
            />
            <img
              src="/img/selecao-produtos/Seta-Aberta_banner.png"
              class="arrow-icon super"
              v-if="produtosSelecionadosLengthSuperBox > 0"
              :class="{ aberta: openSuperBox }"
              @click="openSuperBox = !openSuperBox"
            />
            <img
              src="/img/selecao-produtos/Seta-Abrir_banner.png"
              class="arrow-icon super"
              v-else
              :class="{ aberta: openSuperBox }"
              @click="openSuperBox = !openSuperBox"
            />
          </div>
        </div>

        <div
          class="dropdown superbox"
          :class="{ 'dropdown--open': openSuperBox }"
          v-show="openSuperBox"
        >
          <CardProdutoListaSuperbox
            v-for="produto in produtosSuperBoxLista"
            :key="produto.box + ' superbox'"
            :produto="produto"
            :search="pesquisaProd"
            :tags="values"
            :produtosSuperBox="produtosSuperBox"
            @cardSelected="selecionado"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aos from "aos";
import "aos/dist/aos.css";
import CardProdutoListaSuperbox from "@/components/Produtos/CardProdutoListaSuperbox.vue";
// import ConfirmaSelecao from "@/components/Visuais/ConfirmaSelecao";
import { userPreferenceStore } from "@/stores/userPreference";
import { produtoSuperBox } from "@/stores/superbox-produto.js";
import { mapWritableState, mapActions } from "pinia";
import produtosSuperBoxJson from "@/helpers/produtos_superbox.json";
export default {
  name: "ProdutosSuperbox",

  props: {
    produtos: Array,
    opcaoInicialSelecionada: String,
  },
  components: {
    CardProdutoListaSuperbox,
  },

  data() {
    return {
      contadorSelecionado: false,
      contador: 0,
      search: "",
      values: [],
      texto: true,
      titleSuperBox: "Superbox",
      descSuperBox:
        "Principal compêndio de atributos relativos à Pessoa Física, caracterizando a essência do indivíduo consultado em espectros sociais, econômicos, empresariais e de geolocalidade. Aborda padrões comportamentais, mapeamento de riscos e oportunidade, bem como apontamentos direcionados a partir de alertas.",
      openSuperBox: true,

      pesquisaProd: "",
      liberaPesquisa: false,
      abreDetalhes: false,
    };
  },

  methods: {
    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),

    ativaDetalhes(ativa) {
      this.abreDetalhes = ativa;
    },

    selecionaSuperBox() {
      if (
        this.produtosSelecionadosLengthSuperBox < this.produtosSuperBox.length
      ) {
        this.selecionaTodosSuperBox(true);
      } else {
        this.selecionaTodosSuperBox(false);
      }
    },

    emiteSubstitui(produtos_) {
      this.$emit("substituiProdutos", produtos_);
    },
    selecaoProduto(e) {
      e.Selecionado = !e.Selecionado;
    },

    decideProxPasso() {
      this.$router.push("/consultas/superbox/2");
    },

    passoAnterior() {
      this.$router.push("/consultas/superbox/0");
    },

    highlight(texto) {
      if (!this.pesquisaProd) {
        return texto;
      }

      return texto.replace(new RegExp(this.pesquisaProd, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },

    selecionado(e) {
      if (e.Selecionado) {
        this.contador++;
        this.contadorSelecionado = true;
      } else {
        this.contador--;
        if (this.contador == 0) {
          this.contadorSelecionado = false;
        }
      }
    },

    selecionaTodosSuperBox(e) {
      if (e) {
        this.produtos.forEach((produto) => {
          if (
            !produto.Selecionado &&
            produto.Classificacao == "Superbox" &&
            produto.TipoProduto == "PF"
          ) {
            this.$set(produto, "Selecionado", true);
            this.selecionado(produto);
          }
        });
      } else {
        this.produtos.forEach((produto) => {
          if (
            produto.Selecionado &&
            produto.Classificacao == "Superbox" &&
            produto.TipoProduto == "PF"
          ) {
            this.$set(produto, "Selecionado", false);
            this.selecionado(produto);
          }
        });
      }
    },
  },

  watch: {
    liberaPesquisa() {
      if (!this.liberaPesquisa) {
        this.pesquisaProd = "";
      }
    },
  },

  async mounted() {
    this.produtos.forEach((produto) => {
      produto.Visivel = false;
      if (produto.ApareceNosParametros[this.opcaoInicialSelecionada]) {
        produto.Visivel = true;
      }
    });

    Aos.init({ duration: 800, once: true });

    if (!this.opcaoInicialSelecionada) {
      window.location.href = "/consultas/superbox/0";
    }

    this.selecionaTodosSuperBox(true);

    this.pesquisaProd = "";
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      produtosPreferidos: "produtosPreferidos",
    }),

    ...mapWritableState(produtoSuperBox, {
      produtoSuperBox: "produtoSuperBox",
      listaProdutosSuperBox: "listaProdutosSuperBox",
    }),

    produtosSelecionadosLengthSuperBox() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado && produto.Classificacao === "Superbox";
      }).length;
    },

    produtosSelecionados() {
      let x = this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
      return x;
    },

    produtosSuperBox() {
      return this.produtos.filter(
        (produto) => produto.Classificacao === "Superbox"
      );
    },

    produtosSuperBoxLista() {
      const arrysuperbox = produtosSuperBoxJson.superBox.Blocos;

      const filteredArrysuperbox = arrysuperbox.filter((item) => {
        return (
          item.box === "BOXSUPPF009" ||
          this.produtosSuperBox.some(
            (obrigatorio) => obrigatorio.Nome === item.descricao
          )
        );
      });

      return filteredArrysuperbox;
    },

    iconeTipoPessoa() {
      if (this.opcaoInicialSelecionada == "Pessoa Física") {
        return "face";
      } else if (this.opcaoInicialSelecionada == "Pessoa Jurídica") {
        return "business";
      } else if (this.opcaoInicialSelecionada == "Geolocalização") {
        return "location_on";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.produtos {
  justify-self: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 10px;
}

.linha1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4356a5;
  border-radius: 20px;
  height: 60px;
  background-image: url(/img/superbox/Topo_Padrao_Portal.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 75%;
  max-width: 900px;
}

.nao_encontrado {
  margin: 30px 0;
  grid-column: 1/4;
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.sad {
  font-size: 3rem;
}
.produtos {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  max-width: 100%;
}
.tipo-pessoa-box {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  position: absolute;
  right: 13%;
  top: -35px;

  .icone {
    position: absolute;
    top: -65px;
    font-size: 70px;
    opacity: 0.2;
  }
}
.disclaimer {
  padding: 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  margin: 0 0 5px 0;
}

.searchBox {
  display: flex;
  width: 75%;
  justify-content: flex-start;
  max-width: 900px;
  align-items: center;
  padding: 0 5px;
}

.searchInput {
  margin: 10px 0px 0 40px;
  padding: 5px 0;
  border-radius: 20%;
  max-width: 270px !important;
  min-width: 270px !important;
}

.icon-search {
  font-size: 18px;
  padding: 0;
  height: 18px;
  cursor: default;
  cursor: pointer;
}
.icon-button {
  margin: 5px 0 0 0;
  padding: 5px;
  border-radius: 5px;
  height: 28px;
  cursor: pointer;
  border: none;
  position: absolute;
  z-index: 2;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  &:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.seleciona_todos {
  display: flex;
  gap: 10px;
  width: 30%;
  color: #68686b;
}
.cards {
  width: 75%;
  max-width: 900px;
  margin: 0px 0 10px 0;
  padding: 0 5px;
  gap: 10px;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  height: fit-content;

  &.sem-margem {
    margin: 0px;
  }
}

.cardBox {
  width: 100%;
  border-radius: 20px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.consultas {
    border: 1px solid #1da0f2;
    background-color: white;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  margin: 0;
  border-radius: 20px;
  cursor: pointer;
  z-index: 10;
  transition: 0.5s;

  &.super-box {
    background-color: white;
    color: #4356a5;
    border: solid 1px #1da0f2;
    &.selecionado {
      background-color: #4356a5;
      color: white;
      border: none;
    }
  }

  &.consultas {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px;
    gap: 0px;
  }
}

.header-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  &.tit {
    flex: 1;
  }

  &.linha {
    background-color: #4356a5;
    height: 120px;
    width: 2px;

    &.selecionado {
      background-color: white;
    }
  }

  &.selecao {
    align-items: center;
    margin: 0 40px 0 20px;
  }
}

.img-logo-sb {
  height: 150px;
}

.select-icon {
  height: 50px;
  margin: 30px 0 20px 0;
  position: relative;
}

.arrow-icon {
  height: 40px;
  width: 10px;
  transform: rotate(90deg);
  position: relative;

  &.aberta {
    transform: rotate(270deg);
  }
  &.cinza {
    height: 30px;
    bottom: 20px;
  }
}
.icon {
  height: 46px;
  font-size: 4rem;
  margin-right: 20px;
}

.headerTitle {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 40px;
  width: 60%;

  &.consultas {
    font-size: 22px;
    color: #86878f;
  }
  &.sup {
    line-height: 60px;
  }
}

.descricao {
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
}

.text-desc {
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #68686b;
}
.icone-produtos {
  height: 50px;
  margin: 0 10px;
}
.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  height: 0;
  visibility: hidden;
  transition: height 0.3s ease;
  border-radius: 20px;
  position: relative;
  bottom: 30px;

  &--open {
    width: 100%;
    height: fit-content;
    visibility: visible;
    padding-top: 40px;
  }

  &.superbox {
    background-color: white;
    background-image: url(/img/selecao-produtos/BG-constelacao40.png);
    background-size: 100%;
    background-position: 300px 0;
    background-repeat: no-repeat;
    border: solid 1px #1da0f2;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
  width: 100%;
  padding: 0 0px;
  background-color: transparent;
  .btn-right {
    gap: 10px;
  }
}

.down {
  width: 75%;
  max-width: 890px;
  display: flex;
  position: relative;
  top: 20px;
}

@media (max-width: 1240px) {
  .linha1 {
    min-width: 500px;
  }
  .disclaimer {
    font-size: 1.5rem;
  }

  .header {
    min-width: 500px;
  }
}
@media (max-width: 600px) {
  .produtos {
    overflow-x: scroll;
    align-items: flex-start;
  }
  .headerTitle {
    justify-content: space-between;
  }
  .searchBox {
    width: 100%;
    min-width: 280px;
  }

  .cards {
    width: auto !important;
  }
  .disclaimer {
    min-width: 500px;
  }

  .tipo-pessoa-box {
    right: 0;
    top: -22px;
  }

  .seleciona_todos {
    width: auto;
  }

  .nome {
    width: auto;
  }
}
.disable {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
