<template>
  <div class="campos">
    <div class="linha1">
      <p class="disclaimer">Informe os parâmetros para consulta</p>
    </div>
    <CamposConsulta
      data-aos="fade"
      :campos="campos"
      :produtos="produtosSelecionados"
      :ciclica="false"
      :opcaoInicial="opcaoInicialSelecionada"
      @realizaConsulta="preencheInput"
    />
  </div>
</template>

<script>
import CamposConsulta from "@/components/Inputs/CamposConsulta";

export default {
  props: {
    campos: Array,
    produtos: Array,
    opcaoInicialSelecionada: String,
  },
  data() {
    return {
      camposPreenchidos: {},
    };
  },
  components: {
    CamposConsulta,
  },
  methods: {
    preencheInput() {
      this.camposPreenchidos = {};
      var camposPreenchidos_ = this.campos.filter((campo) => campo.Valor);
      this.campos.forEach((campo) => {
        campo.ValorPossivel = campo.Valor;
      });
      camposPreenchidos_.forEach((campoPreenchido) => {
        this.camposPreenchidos[campoPreenchido.Nome.toLowerCase()] =
          campoPreenchido.Valor;
      });
      this.$emit("realizaConsultaSuperbox", this.camposPreenchidos);
      let control = true;
      this.produtos.forEach((produto) => {
        if (produto.Selecionado == true && control) {
          this.$router.push("/consultas/superbox/3");
          control = false;
        }
      });
    },
  },
  computed: {
    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
  },
  mounted() {
    if (this.$route.query.source === "consulta") {
      let query = { ...this.$route.query };
      delete query.source;
      this.$router.replace({ query });
      this.$emit("limparInputs");
    }

    if (this.produtosSelecionados.length == 0) {
      this.$router.push("/consultas/superbox/0");
      window.location.reload();
    }
    this.analytics("Campos", "Acessos", "Entrou no preenchimento de campos");
  },
};
</script>

<style scoped>
.campos {
  margin-top: 0px;
  max-width: 70%;
}

.linha1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 60px;
  background-image: url(/img/superbox/Topo_Padrao_Portal.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 50vw;
  max-width: 900px;
  margin-bottom: 40px;
}

.disclaimer {
  padding: 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  margin: 0 0 5px 0;
}
</style>
