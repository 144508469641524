<template>
  <div class="view-wrapper" v-if="liberaTela">
    <StepsSuperbox
      v-if="verificaRotasSteps()"
      :produtos="produtos"
      :campos="campos"
      :opcaoInicialSelecionada="opcaoInicial"
      :contexto="contexto"
    />
    <div class="view">
      <router-view
        @substituiProdutos="substituiProdutos"
        @realizaConsultaSuperbox="realizaConsultaSuperbox"
        @selecionaInicial="selecionaInicial"
        @addRequisitado="addRequisitado"
        @limparInputs="limparInputs"
        @realizaConsultaAdicional="realizaConsultaAdicional"
        @recuperaConsultaSelecionada="recuperaConsultaSelecionada"
        @resetTipoPessoa="resetTipoPessoa"
        @resetContextoTrilha="resetContextoTrilha"
        :produtos="produtos"
        :campos="campos"
        :opcaoInicialSelecionada="opcaoInicial"
        :produtosSelecionados="produtosSelecionados"
        :contexto="contexto"
        :codigo_agrupador="codigo_agrupador"
        :modeloSelecionado="modeloSelecionado"
        :resultadoModelo="resultadoModelo"
        :consultasSelecionadas="consultasSelecionadas"
        :resultadoConsultaAdd="resultadoConsultaAdd"
        :terminouConsulta="terminouConsulta"
        :liberaBlocoAlerta="liberaBlocoAlerta"
        :liberaTelaSuperbox="liberaTelaSuperbox"
        :liberaConsultaSuperbox="liberaConsultaSuperbox"
      />
    </div>
  </div>
</template>

<script>
import "aos/dist/aos.css";
import { criaCampos } from "@/helpers";
import { sessionStore } from "../../stores/session";
import { produtosStore } from "@/stores/produtos";
import { consultasService } from "@/services";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { userPreferenceStore } from "@/stores/userPreference";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapActions, mapWritableState } from "pinia";
import StepsSuperbox from "@/components/Visuais/StepsSuperbox.vue";
import jsonProdutos from "@/helpers/produtos.json";

export default {
  name: "NovaSup",

  components: {
    StepsSuperbox,
  },

  data() {
    return {
      liberaTela: false,

      campos: [],
      opcaoInicial: "",
      contexto: [],

      modeloSelecionado: {},
      resultadoModelo: {},
      consultasSelecionadas: [],
      msgErro: "",
      resultadoConsultaAdd: {},
      terminouConsulta: false,
      liberaBlocoAlerta: false,

      liberaTelaSuperbox: false,
      liberaConsultaSuperbox: false,
      novaConsultaSelecionada: {},
    };
  },

  watch: {
    opcaoInicial: function (newVal, oldVal) {
      this.produtos.forEach((produto) => {
        produto.Selecionado = false;
      });
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Disabled = false;
        campo.Requerido = false;
      });

      // if (this.opcaoInicial == "") {
      // this.contexto = [];
      // this.$router.push("/consultas/superbox/0");
      // }
    },

    "produtosSelecionados.length": function (newVal, oldVal) {
      // this.contexto = [];

      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Requerido = false;
        campo.Disabled = false;
      });
    },

    //retirar depois
    usuarioLogado: {
      handler: function () {
        if (this.usuarioSemAcesso) {
          this.$router.push("/inicio");
        }
      },
      deep: true,
    },

    consultasTrilha: {
      handler(newVal, oldVal) {
        if (newVal.length > 1) {
          const novaConsulta = this.consultasTrilha.find(
            (consulta) => consulta.cod_agrupador == "loading"
          );

          if (novaConsulta) {
            this.novaConsultaSelecionada = novaConsulta;
            this.tipoConsultaSelecionada = novaConsulta.consulta;

            if (novaConsulta.consulta == "Modelos") {
              this.realizaConsultaCiclica(
                this.novaConsultaSelecionada.parametros
              );
            } else if (this.novaConsultaSelecionada.consulta == "Superbox") {
              this.realizaConsultaCiclicaSuperbox(
                this.novaConsultaSelecionada.parametros
              );
            }
          }
        }
      },
      deep: true,
    },

    ativaNovaConsultaMod(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.codigo_agrupador != this.trilhaSelecionada.cod_agrupador) {
          this.liberaTelaSuperbox = false;
          this.recuperaConsultaSelecionada();
        }
      }
    },
  },

  methods: {
    reset() {
      this.resultadoModelo = {};
      this.resultadoConsultaAdd = {};

      // this.contexto = [];
      this.resetaCodigoAgrupador();
      this.produtos.forEach((produto) => {
        produto.Selecionado = false;
      });
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Disabled = false;
        campo.Requerido = false;
      });
    },

    resetTipoPessoa() {
      if (this.opcaoInicial == "Pessoa Jurídica") {
        this.opcaoInicial = "";
      }
    },

    recuperaConsultaSelecionada() {
      this.terminouConsulta = false;

      var selecao = this.consultasTrilha.find(
        (consulta) =>
          consulta.cod_agrupador == this.trilhaSelecionada.cod_agrupador
      );

      if (this.trilhaSelecionada.consulta == "Modelos") {
        if (this.codigo_agrupador != selecao.cod_agrupador) {
          this.liberaTelaSuperbox = false;
          this.codigo_agrupador = selecao.cod_agrupador;
          this.opcaoInicial = selecao.historico.tipo_pessoa;
          this.resultadoModelo = selecao.historico.resultadoModelo;
          this.consultasSelecionadas = selecao.historico.consultasSelecionadas;
          this.resultadoConsultaAdd = selecao.historico.resultadoConsultaAdd;
          this.liberaBlocoAlerta = selecao.historico.liberaBlocoAlerta;
          this.modeloSelecionado = selecao.historico.modeloSelecionado;
        }
      } else if (this.trilhaSelecionada.consulta == "Superbox") {
        this.opcaoInicial =
          selecao.tipo_pessoa == "PF" ? "Pessoa Física" : "Pessoa Jurídica";
        this.liberaConsultaSuperbox = false;
        this.contexto = selecao.historico.contexto;
        this.consultaErros = selecao.historico.consultaErros;
        this.listaBlocos = selecao.historico.listaBlocos;
        this.codigo_agrupador = selecao.cod_agrupador;
        this.liberaTelaSuperbox = true;
        this.atualizarSelecaoProdutos(selecao);
      }

      this.terminouConsulta = true;
      this.atualizaTelaResultadosModelos();
    },

    atualizarSelecaoProdutos(selecao) {
      this.produtos.forEach((produto) => {
        if (selecao.historico.produtosSelecionados) {
          const existe = selecao.historico.produtosSelecionados.some(
            (prodSelec) => prodSelec.Box === produto.Box
          );

          produto.Selecionado = existe;
        }
      });
    },

    addRequisitado(recomendado, id) {
      const _contexto = { ...this.contexto[id] };
      _contexto.requisitados[recomendado] = true;
      this.$set(this.contexto, id, _contexto);
      this.$nextTick(() => {
        const box = document.getElementById(recomendado + id);
        const content = document.querySelector(".content");
        content.classList.add("margin-bottom-adapter");
        box.scrollIntoView();
        setTimeout(() => {
          content.classList.remove("margin-bottom-adapter");
        }, 5000);
      });
    },

    verificaRotasSteps() {
      var rota = this.$route.fullPath.slice(-1);

      if (parseInt(rota) < 3) {
        return true;
      } else {
        return false;
      }
    },

    resetContextos() {
      this.resetaCodigoAgrupador();
      this.contexto = [];
    },

    resetContextoTrilha() {
      this.liberaTelaSuperbox = false;
      this.liberaConsultaSuperbox = false;
      this.novaConsultaSelecionada = {};
    },

    async realizaConsultaSuperbox() {
      this.resetContextos();
      this.resetContextoTrilha();
      this.resetAlertasPf();
      this.resetConsutaAdd();

      var contexto_idx = this.contexto.length;
      this.contexto[contexto_idx] = {
        id: contexto_idx,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
      };

      this.produtosSelecionados.forEach((produto) => {
        this.$set(this.contexto[contexto_idx].requisitados, produto.Box, true);
      });
      // this.criaCodigoAgrupador();
      await this.geraCodigoAgrupadorMetadados("consulta_superbox");

      this.tipoConsultaSelecionada = "Superbox";
      this.liberaConsultaSuperbox = true;
      this.liberaTelaSuperbox = true;

      const parametrosComValor = this.campos.reduce((obj, item) => {
        if (item.Valor !== "") {
          obj[item.Nome.toLowerCase()] = item.Valor;
        }
        return obj;
      }, {});

      var novaConsulta = {
        id: 1,
        origem: null,
        cod_agrupador: "loading",
        tipo_pessoa: this.opcaoInicial === "Pessoa Física" ? "PF" : "PJ",
        consulta: this.tipoConsultaSelecionada,
        consultasSelecionadas: this.consultasSelecionadas,
        modeloSelecionado: "",
        parametros: {
          data_ref: this.dataDaConsulta,
          ...parametrosComValor,
        },
        historico: {},
      };

      this.consultasTrilha.push(novaConsulta);
    },

    substituiProdutos(produtos) {
      this.produtos.forEach((produtoOriginal) => {
        produtos.forEach((produto) => {
          if (
            produtoOriginal.Box == produto.Box &&
            produtoOriginal.ApareceNosParametros == produto.ApareceNosParametros
          ) {
            produtoOriginal.Selecionado = produto.Selecionado;
          }
        });
      });
    },

    selecionaInicial(opcao) {
      this.opcaoInicial = opcao;
    },

    limparInputs() {
      // this.campos.forEach((campo) => {
      //   campo.Valor = "";
      // });
      // document.getElementById("input-CPF").value = "";
    },

    async verificaPreferenciasSuperbox() {
      const preferenciaNome = "PortalOutbox_consultaSuperBox";

      const preferencia = await this.syncPreferenciaGeral(preferenciaNome);

      if (preferencia.Valor != null) {
        const valorGet = preferencia.Valor;
        const objetos = valorGet.split(";").map((item) => {
          const valores = item.split("=");
          const regex = /(\d+)$/;
          if (valores[0] != valores[1]) {
            this.produtos.forEach((produto) => {
              if (produto.Box === valores[0]) {
                produto.Box = valores[1];
              }
            });
          }
        });
      }
    },

    async realizaConsultaCiclica(resultado) {
      const printCodAgr = this.codigo_agrupador
      this.terminouConsulta = false;
      this.reset();
      this.consultasSelecionadas = [];
      this.liberaBlocoAlerta = false;

      this.consultasSelecionadas =
        this.novaConsultaSelecionada.consultasSelecionadas;

      const blocoAlerta = this.consultasSelecionadas.some((consulta) =>
        consulta.Nome.includes("Alertas Gerais")
      );

      this.resetaCodigoAgrupador();
      if (blocoAlerta) {
        await this.geraCodigoAgrupadorMetadados(
          `consulta_modelo;bloco_alerta;origemTrilha=${printCodAgr}`
        );
      } else {
        await this.geraCodigoAgrupadorMetadados(
          `consulta_modelo;origemTrilha=${printCodAgr}`
        );
      }

      var novaConsulta = this.consultasTrilha.find(
        (obj) => obj.cod_agrupador === "loading"
      );

      if (novaConsulta) {
        novaConsulta.cod_agrupador = this.codigo_agrupador;
        this.modeloSelecionado = novaConsulta.modeloSelecionado;
      }

      const mapeamento = {
        PF: "pessoa_fisica",
        PJ: "pessoa_juridica",
      };

      const mapeamentoSigla = {
        PF: "Pessoa Física",
        PJ: "Pessoa Jurídica",
      };

      this.opcaoInicial = mapeamentoSigla[novaConsulta.tipo_pessoa];

      const objetoConsulta = {
        cep: "",
        tipo_pessoa: mapeamento[novaConsulta.tipo_pessoa],
        cpf_cnpj: "",
        cod_ref: novaConsulta.modeloSelecionado.Valor,
        data_ref: this.dataDaConsulta,
        modo_resultado: "codigo",
        parametros_adicionais: {},
        identificador_etiqueta_primaria: "PRD",
        identificadores_etiquetas_secundarias: "",
        cod_agrupador: this.codigo_agrupador,
      };

      for (const chave in resultado) {
        if (chave === "cpf" || chave === "cnpj") {
          objetoConsulta["cpf_cnpj"] = resultado[chave];
        } else {
          if (objetoConsulta.hasOwnProperty(chave)) {
            objetoConsulta[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          } else {
            objetoConsulta.parametros_adicionais[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          }
        }
      }

      await consultasService
        .realizaConsultaModelo(objetoConsulta)
        .then((resultadoModelo) => {
          this.resultadoModelo = resultadoModelo;
        });

      if (this.resultadoModelo.erro) {
        this.msgErro = this.resultadoModelo.erro;
      }

      if (this.consultasSelecionadas.length > 0) {
        await this.realizaConsultaAdicional(resultado);
      }

      var historico = {};
      historico = {
        cod_agrupador: this.codigo_agrupador,
        tipo_pessoa: this.opcaoInicial,
        resultadoModelo: this.resultadoModelo,
        consultasSelecionadas: this.consultasSelecionadas,
        resultadoConsultaAdd: this.resultadoConsultaAdd,
        liberaBlocoAlerta: this.liberaBlocoAlerta,
        modeloSelecionado: this.modeloSelecionado,
      };

      novaConsulta.historico = historico;

      this.trilhaSelecionada = this.consultasTrilha.find(
        (consulta) => consulta.cod_agrupador == this.codigo_agrupador
      );

      this.terminouConsulta = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    async realizaConsultaCiclicaSuperbox(resultado) {
      this.liberaTelaSuperbox = false;
      const printCodAgr = this.codigo_agrupador

      this.resetContextos();
      this.resetContextoTrilha();
      await this.geraCodigoAgrupadorMetadados(
        `consulta_superbox;origemTrilha=${printCodAgr}`
      );

      this.selecionaTodosSuperBox();
      const produtosSelecionados = this.produtos.filter((produto) => {
        return produto.Selecionado;
      });

      this.campos = Object.keys(resultado).map((key) => {
        return {
          Nome: key.toUpperCase(),
          Valor: resultado[key],
        };
      });

      var contexto_idx = this.contexto.length;
      this.contexto[contexto_idx] = {
        id: contexto_idx,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
      };

      produtosSelecionados.forEach((produto) => {
        this.$set(this.contexto[contexto_idx].requisitados, produto.Box, true);
      });

      this.liberaConsultaSuperbox = true;
      this.liberaTelaSuperbox = true;

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1000);
    },

    selecionaTodosSuperBox() {
      this.produtos.forEach((produto) => {
        if (
          !produto.Selecionado &&
          produto.Classificacao == "Superbox" &&
          produto.TipoProduto == "PF"
        ) {
          this.$set(produto, "Selecionado", true);
        } else if (produto.Selecionado && produto.Classificacao != "Superbox") {
          this.$set(produto, "Selecionado", false);
        }
      });
    },

    async realizaConsultaAdicional(resultado) {
      const mapeamento = {
        "Pessoa Física": "pessoa_fisica",
        "Pessoa Jurídica": "pessoa_juridica",
      };
      let tipoPessoa = mapeamento[this.opcaoInicial];

      for (const i in this.consultasSelecionadas) {
        if (this.consultasSelecionadas[i].Valor != "BOXSUPPJ009") {
          var objetoConsulta = {};
          objetoConsulta["cpf_cnpj"] =
            tipoPessoa == "pessoa_fisica" ? resultado.cpf : resultado.cnpj;
          objetoConsulta["cep"] = resultado.cep;
          objetoConsulta["cod_ref"] = this.consultasSelecionadas[i].Valor;
          objetoConsulta["tipo_pessoa"] = tipoPessoa;
          objetoConsulta["modo_resultado"] = "codigo";
          objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
          objetoConsulta["data_ref"] = resultado.data_ref || "";

          await consultasService
            .realizaConsultaBox(objetoConsulta)
            .then((resultado) => {
              this.resultadoConsultaAdd[this.consultasSelecionadas[i].Valor] =
                resultado;
            });
          this.liberaBlocoAlerta = false;
        } else {
          this.liberaBlocoAlerta = true;
        }
      }
    },

    async recuperaPreferencias() {
      const preferenciasFavoritos = await this.syncPreferenciaGeral(
        "PortalOutbox_modelosFavoritos"
      );

      if (preferenciasFavoritos === undefined) {
        await this.createPreferencia("PortalOutbox_modelosFavoritos");
      } else {
        const valor = preferenciasFavoritos.Valor;
        if (valor != "") {
          this.modelosPreferidos = valor.split(";");
          this.modelosPreferidos.sort();
        }
      }
      const preferenciasFavoritosAdicionais = await this.syncPreferenciaGeral(
        "PortalOutbox_consultaAdicionaisModelos"
      );

      if (preferenciasFavoritosAdicionais === undefined) {
        var preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";
        var preferenciaString =
          "Dados Cadastrais;Mapa Geral de Riscos;Informações Cadastrais;Detalhamentos Cadastrais da Matriz";

        await this.createPreferencia(preferenciaNome);
        await this.substituiPreferencia(preferenciaNome, preferenciaString);
      } else {
        const valor = preferenciasFavoritosAdicionais.Valor;
        if (valor != "") {
          this.modelosConsultasAddFavoritos = valor.split(";");
          this.modelosConsultasAddFavoritos.sort();
        }
      }

      // this.modelosConsultasAddFavoritos = await this.syncPreferenciaGeral(
      //   "PortalOutbox_consultaAdicionaisModelos"
      // );
      // if (this.modelosConsultasAddFavoritos === undefined) {
      //   const preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";
      //   const preferenciasString = "Dados Cadastrais";
      //   await this.createPreferencia(preferenciaNome);
      // await this.substituiPreferencia(preferenciaNome, preferenciasString);

      // this.consultasAdicionais.forEach((produto) => {
      //   if (produto.Nome == preferenciaNome) {
      //     this.selecionaConsultas(produto);
      //   }
      // });
      // } else {
      //   const valorGet = this.modelosConsultasAddFavoritos.Valor;
      //   var objetos = valorGet.split(";");

      // //preferencias disponíveis para pf
      // if (this.opcao_inicial == "Pessoa Física") {
      //   objetos = objetos.filter(
      //     (item) =>
      //       item == "Dados Cadastrais" || item == "Mapa Geral de Riscos"
      //   );
      // }
      // //preferencias disponíveis para pj
      // if (this.opcao_inicial == "Pessoa Jurídica") {
      //   objetos = objetos.filter(
      //     (item) =>
      //       item == "Informações Cadastrais" ||
      //       item == "Detalhamentos Cadastrais da Matriz"
      //   );
      // }

      // const index = this.consultasSelecionadas.findIndex((item) =>
      //   objetos.includes(item.Nome)
      // );

      // if (index === -1) {
      //   this.consultasAdicionais.forEach((produto) => {
      //     if (objetos.includes(produto.Nome)) {
      //       this.selecionaConsultas(produto);
      //     }
      //   });
      // }
      // }
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),

    ...mapActions(produtosStore, [
      "getProdutos",
      "getModelos",
      "resetStore",
      "criaCodigoAgrupador",
      "resetaCodigoAgrupador",
      "geraCodigoAgrupadorMetadados",
    ]),
    ...mapActions(storeAlertasSuperBox, ["resetAlertasPf"]),

    ...mapActions(dadosConsultaAddStore, [
      "resetConsutaAdd",
      "atualizaTelaResultadosModelos",
    ]),

    ...mapActions(userPreferenceStore, ["syncPreferenciaGeral"]),

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  computed: {
    usuarioSemAcesso() {
      if (Object.keys(this.usuarioLogado).length > 0) {
        return (
          !this.usuarioLogado.Grupos.includes(
            "Usuários StepWise com acesso ao SuperBox"
          ) &&
          !this.usuarioLogado.Grupos.includes(
            "Acesso de clientes ao Portal Outbox - SuperBox"
          )
        );
      } else {
        return false;
      }
    },

    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      modelos: "modelos",
      codigo_agrupador_box: "codigo_agrupador_box",
      codigo_agrupador: "codigo_agrupador",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaTrilhaConsultas: "ativaTrilhaConsultas",
      consultasTrilha: "consultasTrilha",
      tipoConsultaSelecionada: "tipoConsultaSelecionada",
      codRefSelecionado: "codRefSelecionado",
      trilhaSelecionada: "trilhaSelecionada",
      ativaNovaConsultaMod: "ativaNovaConsultaMod",
      dataDaConsulta: "dataDaConsulta",
    }),

    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },

    camposComValor() {
      return this.produtos.filter((produto) => {
        return produto.Valor;
      });
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      modelosPreferidos: "modelosPreferidos",
      modelosConsultasAddFavoritos: "modelosConsultasAddFavoritos",
    }),
  },

  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    this.resetAlertasPf();
    this.resetStore();
    this.resetConsutaAdd();
    this.resetContextos();
    this.resetContextoTrilha();

    if (this.opcao_inicial == undefined) {
      if (!this.$route.path.includes("/0")) {
        this.$router.push("/consultas/superbox/0");
      }
    }

    await this.getProdutos();
    this.liberaTela = true;
    this.campos = criaCampos.montaCampos(jsonProdutos);
    const store = sessionStore();
    store.$reset();
    await this.verificaPreferenciasSuperbox();

    if (this.usuarioSemAcesso) {
      this.$router.push("/inicio");
    }

    await this.getModelos();
    this.recuperaPreferencias();
  },
};
</script>

<style scoped>
.view-wrapper {
  margin-top: 10px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.absolute {
  display: none;
}

.view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .view-wrapper {
    margin-top: 30px;
    gap: 30px;
  }
  .absolute {
    display: block;
    position: absolute;
    height: 30px;
    width: 50px;
    top: 20px;
    left: 100px;
  }
}
</style>
